import { useCallback, type FunctionComponent } from 'react';

import type {
  Suggestion,
  SuggestionItemData,
} from '@/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/suggestions';

import { SUGGESTIONS_WITH_QUERY_CATEGORY_INDEXES } from '../Suggestions/constants';
import { SuggestionBlockTitle } from './components/SuggestionBlockTitle/SuggestionBlockTitle';
import { SuggestionChipItem } from './components/SuggestionChipItem/SuggestionChipItem';
import { SuggestionItem } from './components/SuggestionItem/SuggestionItem';

interface Props {
  suggestions: Suggestion[];
  startingPosition: number;
  title?: string;
  autoComplete?: boolean;
  onClick?(suggestionItemData: SuggestionItemData): void;
  onRemove?(name?: string): void;
  onActionLinkClick?(): void;
  actionLinkTitle?: string;
}

export const SuggestionBlock: FunctionComponent<Props> = ({
  suggestions,
  startingPosition,
  title,
  autoComplete = false,
  onClick,
  onRemove,
  onActionLinkClick,
  actionLinkTitle,
}) => {
  const isBrandSuggestion = suggestions[0]?.type === 'brand';
  const isCategorySuggestion = suggestions[0]?.type === 'category';
  const isChipBlock = isBrandSuggestion || isCategorySuggestion;

  const renderSuggestionItem = useCallback(
    (
      { name, slug, type, inHistory, objectID, queryCategory }: Suggestion,
      index: number,
    ) => {
      const currentPosition = startingPosition + index;
      const scopedQueryCategory =
        SUGGESTIONS_WITH_QUERY_CATEGORY_INDEXES.includes(index)
          ? queryCategory
          : undefined;
      switch (type) {
        case 'brand':
        case 'category':
          return (
            <SuggestionChipItem
              name={name}
              slug={slug}
              type={type}
              objectID={objectID}
              onClick={onClick}
              key={`${type}-${slug}-${currentPosition}`}
              position={currentPosition}
            />
          );
        default:
          return (
            <SuggestionItem
              name={name}
              slug={slug}
              type={type}
              objectID={objectID}
              inHistory={!autoComplete && inHistory}
              onClick={onClick}
              onRemove={onRemove}
              key={`${type}-${slug}-${currentPosition}`}
              position={currentPosition}
              scopedQueryCategory={scopedQueryCategory}
            />
          );
      }
    },
    [startingPosition, onClick, onRemove, autoComplete],
  );

  return (
    <li className="relative mt-s w-full first:mt-0" key={startingPosition}>
      {title && ((autoComplete && isBrandSuggestion) || !autoComplete) && (
        <SuggestionBlockTitle
          title={title}
          onActionLinkClick={onActionLinkClick}
          actionLinkTitle={actionLinkTitle}
        />
      )}
      <div className={isChipBlock ? 'flex flex-wrap gap-xs' : undefined}>
        {suggestions.map(renderSuggestionItem)}
      </div>
    </li>
  );
};

SuggestionBlock.displayName = 'SuggestionBlock';
