import { put, select } from 'typed-redux-saga';

import { isMicroServiceError } from '@/domains/core/httpClient/utils/isMicroServiceError';
import { selectVisitorId } from '@/domains/core/tracking/selectors/selectVisitorId';
import { authSelector } from '@/domains/customerManagement/customerManagement.selectors';
import {
  ADD_TO_CART_SUCCESS,
  AddToCartSuccessAction,
} from '@/domains/shoppingCart/AddToCart/AddToCart.actions';
import { MoveItemToMainSuccessAction } from '@/domains/shoppingCart/CartPage/AsideCart/actions/MoveItemToMain.actions';
import { MainCartItemAdditionSuccessAction } from '@/domains/shoppingCart/CartPage/MainCart/actions/MainCartItemAddition.actions';
import { MainCartItemDeletionSuccessAction } from '@/domains/shoppingCart/CartPage/MainCart/actions/MainCartItemDeletion.actions';
import { MainCartItemEditionSuccessAction } from '@/domains/shoppingCart/CartPage/MainCart/actions/MainCartItemEdition.actions';
import { NewMainCartRetrievalRequestAction } from '@/domains/shoppingCart/CartPage/MainCart/actions/MainCartRetrieval.actions.default';
import { MoveItemToAsideSuccessAction } from '@/domains/shoppingCart/CartPage/MainCart/actions/MoveItemToAside.actions';
import { logShoppingCartError } from '@/domains/shoppingCart/common/utils/logShoppingCartError';
import { formatForCartOverview } from '@/domains/shoppingCart/services/msCart/utils/formatForCartOverview';
import { selectAddToCartState } from '@/shoppingCart/AddToCart/selectors/selectAddToCartState';
import {
  GET_CART_OVERVIEW_FAILED,
  GET_CART_OVERVIEW_SUCCESS,
} from '@/shoppingCart/CartOverview/actions/GetCartOverview.actions';
import {
  TTL_FIVE_MINUTES,
  TTL_SEVEN_DAYS,
} from '@/shoppingCart/CartOverview/CartOverview.constants';
import { type CartOverviewProduct } from '@/shoppingCart/CartOverview/interfaces/CartOverview';
import { writeCartOverviewToLocalStorage } from '@/shoppingCart/CartOverview/localStorage/writeCartOverviewToLocalStorage';
import { selectCartOverview } from '@/shoppingCart/CartOverview/selectors/selectCartOverview';
import { addProductToCartOverview } from '@/shoppingCart/CartOverview/utils/addProductToCartOverview';
import {
  ASIDE_CART_RETRIEVAL_SUCCESS,
  AsideCartRetrievalSuccessAction,
} from '@/shoppingCart/CartPage/AsideCart/actions/AsideCartRetrieval.actions';
import { selectMainCart } from '@/shoppingCart/selectors/selectMainCart';

type CartOverviewUpdate =
  | AddToCartSuccessAction
  | MainCartItemAdditionSuccessAction
  | MainCartItemDeletionSuccessAction
  | MainCartItemEditionSuccessAction
  | AsideCartRetrievalSuccessAction
  | NewMainCartRetrievalRequestAction
  | MoveItemToAsideSuccessAction
  | MoveItemToMainSuccessAction;

export function* cartOverviewUpdate(action: CartOverviewUpdate) {
  try {
    const cartOverview = yield* select(selectCartOverview);
    const { isAuthenticated, userId, isAuthenticating } =
      yield* select(authSelector);
    const visitorId = yield* select(selectVisitorId);

    if (!isAuthenticating && cartOverview) {
      const owner = `${isAuthenticated ? userId : visitorId}`;
      const ttl = isAuthenticated ? TTL_FIVE_MINUTES : TTL_SEVEN_DAYS;

      let newProducts: CartOverviewProduct[];
      let productsTotalPrice: number | undefined;

      if (action.type === ADD_TO_CART_SUCCESS) {
        const addedToCart = yield* select(selectAddToCartState);
        newProducts = addProductToCartOverview(
          addedToCart.products,
          cartOverview.contents,
        );
        productsTotalPrice = addedToCart.productsTotalPrice || 0;
      } else {
        const { overview, prices } = yield* select(selectMainCart);
        newProducts = Object.entries(overview || []).map(([id, quantity]) => ({
          id: Number(id),
          quantity,
        }));
        productsTotalPrice = prices?.cartTotalPrice || 0;
      }

      const formattedForCartOverview = formatForCartOverview({
        products: newProducts,
        id: cartOverview.id,
      });

      yield* put({
        type: GET_CART_OVERVIEW_SUCCESS,
        cartOverview: { ...formattedForCartOverview, productsTotalPrice },
      });

      // NOTE Used to avoid erasing productsTotalPrice as
      //      ASIDE_CART_RETRIEVAL_SUCCESS doesn't return the cart total.
      if (action.type !== ASIDE_CART_RETRIEVAL_SUCCESS) {
        writeCartOverviewToLocalStorage({
          cartOverviewContents: formattedForCartOverview.contents,
          id: formattedForCartOverview.id,
          owner,
          ttl,
          productsTotalPrice,
        });
      }
    }
  } catch (error) {
    if (!isMicroServiceError(error)) {
      logShoppingCartError(error, 'cartOverview', 'cartOverviewUpdate');
    }
    yield* put({ type: GET_CART_OVERVIEW_FAILED, error: error?.message });
  }
}
