import { useEffect, useRef, type FunctionComponent } from 'react';
import type React from 'react';
import { twJoin } from 'tailwind-merge';

import { Link } from '@/core/routing/components/Link';
import { useSearchFocusedSuggestionPosition } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchFocusedSuggestionPosition';
import type {
  QueryCategory,
  SuggestionItemData,
  SuggestionType,
} from '@/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/suggestions';
import { cleanHtmlTags } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/utils/cleanHtmlTags';

import { RemoveButton } from './components/RemoveButton/RemoveButton';

interface Props {
  name: string;
  onClick?: (suggestionItemData: SuggestionItemData) => void;
  onRemove?: (name?: string) => void;
  slug: string;
  type: SuggestionType;
  position: number;
  objectID?: string;
  inHistory?: boolean;
  prefix?: string;
  scopedQueryCategory?: QueryCategory;
}

export const SuggestionItem: FunctionComponent<Props> = ({
  name,
  onClick,
  onRemove,
  slug,
  type,
  position,
  objectID = '',
  inHistory,
  prefix,
  scopedQueryCategory,
}) => {
  const itemRef = useRef<HTMLDivElement | null>(null);
  const focusedSuggestedPosition = useSearchFocusedSuggestionPosition();
  const historyItem = inHistory || type === 'history';
  const hasQueryCategory = !!(
    scopedQueryCategory?.name && scopedQueryCategory?.id
  );

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick({
        label: name,
        type,
        position,
        objectID,
        inHistory: historyItem,
        hasQueryCategory,
        event: e,
      });
    }
  };

  const handleRemove = () => {
    if (onRemove) {
      onRemove(name);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && itemRef.current?.childNodes[0]) {
      e.preventDefault();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      itemRef.current?.childNodes[0].click();
    }
  };

  useEffect(() => {
    if (itemRef.current && position === focusedSuggestedPosition) {
      itemRef.current.focus();
    }
  }, [itemRef, position, focusedSuggestedPosition]);

  return (
    <div
      className="flex max-w-full items-center hover:rounded-container
        hover:bg-foundation-disabled"
      tabIndex={position}
      ref={itemRef}
      onKeyDown={handleKeyDown}
      role="link"
    >
      <Link
        href={hasQueryCategory ? scopedQueryCategory.slug : slug}
        className={twJoin(
          `flex max-w-full flex-1 flex-wrap items-center px-[6px] py-[6px]
          tracking-wide`,
          hasQueryCategory && 'max-h-[54px]',
        )}
        onClick={handleClick}
        data-testid={`search-${type}-link-${position}`}
        title={cleanHtmlTags(name)}
      >
        {historyItem}

        {/* eslint-disable react/no-danger */}
        <span
          className={twJoin(
            'inline-block truncate first-letter:uppercase',
            hasQueryCategory && 'mr-xs',
          )}
          data-prefix={prefix}
          dangerouslySetInnerHTML={{ __html: name }}
        />

        {hasQueryCategory && (
          <span
            className="inline-block truncate rounded-card bg-foundation-primary
              px-xs py-[1px] align-top text-small text-foundation-secondary
              first-letter:uppercase"
          >
            {scopedQueryCategory.name.toLowerCase()}
          </span>
        )}
      </Link>
      {historyItem && (
        <RemoveButton
          onClick={handleRemove}
          dataTestId={`search-${type}-remove-${position}`}
        />
      )}
    </div>
  );
};

SuggestionItem.displayName = 'SuggestionItem';
