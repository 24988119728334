import { Component, type ReactNode } from 'react';

import logger from '@/domains/core/observability/logger';

import { SearchFallback } from '../Search/components/SearchFallback/SearchFallback';

interface Props {
  container?: string;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <SearchFallback />;
    }
    return children;
  }
}
