import { useEffect, useRef, type FunctionComponent } from 'react';

import { Link } from '@/core/routing/components/Link';
import { Chip } from '@/core/tamagoshiTailwind/components/Chip/Chip';
import { useSearchFocusedSuggestionPosition } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchFocusedSuggestionPosition';
import type {
  SuggestionItemData,
  SuggestionType,
} from '@/productDiscovery/HeaderLeonidas/modules/searchBar/interfaces/suggestions';

interface Props {
  name: string;
  onClick?: (suggestionItemData: SuggestionItemData) => void;
  slug: string;
  type: SuggestionType;
  position: number;
  objectID?: string;
}

export const SuggestionChipItem: FunctionComponent<Props> = ({
  name,
  onClick,
  slug,
  type,
  position,
  objectID = '',
}) => {
  const itemRef = useRef<HTMLDivElement | null>(null);
  const focusedSuggestedPosition = useSearchFocusedSuggestionPosition();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick({ label: name, type, position, objectID, event: e });
    }
  };

  useEffect(() => {
    if (itemRef.current && position === focusedSuggestedPosition) {
      itemRef.current.focus();
    }
  }, [itemRef, position, focusedSuggestedPosition]);

  return (
    <div
      tabIndex={position}
      ref={itemRef}
      role="link"
      className="max-w-full pt-xs"
    >
      <Link href={slug} onClick={handleClick}>
        <Chip
          key={name}
          data-testid={`search-${type}-link-${position}`}
          text={name}
          selected={false}
        />
      </Link>
    </div>
  );
};

SuggestionChipItem.displayName = 'SuggestionChipItem';
