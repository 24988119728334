import classnames from 'classnames';
import { PropsWithChildren, type FunctionComponent } from 'react';

import { Skeleton } from '@/productDiscovery/HeaderLeonidas/modules/productSuggestions/components/Skeleton/Skeleton';
import { rootLabel } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Suggestions/translations';
import { useAvailableSuggestions } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useAvailableSuggestions';
import { useDisplayEmptySearch } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useDisplayEmptySearch';
import { useSearchSuggestionsVisibility } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/hooks/useSearchSuggestionsVisibility';

import { AutoCompleteSuggestions } from './components/AutoCompleteSuggestions/AutoCompleteSuggestions';
import { EmptySearchSuggestions } from './components/EmptySearchSuggestions/EmptySearchSuggestions';
import { useKeyDown } from './hooks/useKeyDown';

import styles from './Suggestions.module.scss';

export const Suggestions: FunctionComponent = () => {
  const suggestionsVisible = useSearchSuggestionsVisibility();

  const displayEmptySearch = useDisplayEmptySearch();

  const TypeSuggestions = displayEmptySearch
    ? EmptySearchSuggestions
    : AutoCompleteSuggestions;

  return (
    <Root suggestionsVisible={suggestionsVisible}>
      <ul
        className={classnames(styles.wrapper, {
          [styles.autocompleteDesign]: !displayEmptySearch,
        })}
      >
        {suggestionsVisible ? <TypeSuggestions /> : <Skeleton />}
      </ul>
    </Root>
  );
};

Suggestions.displayName = 'Suggestions';

function Root({
  children,
  suggestionsVisible,
}: PropsWithChildren<{ suggestionsVisible: boolean }>) {
  const availableSuggestions = useAvailableSuggestions();

  const values = availableSuggestions
    ? Object.values(availableSuggestions).flat()
    : [];

  const onKeyDown = useKeyDown(values);

  return (
    <div
      aria-label={rootLabel}
      className={classnames(styles.root, {
        [styles.visible]: suggestionsVisible,
      })}
      onKeyDown={onKeyDown}
      role="searchbox"
      tabIndex={0}
    >
      {children}
    </div>
  );
}
